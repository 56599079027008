import { flare } from '@flareapp/js';

const PRODUCTION_DOMAIN = 'kundenportal.techem.at';
const RELEASE_DOMAIN = 'kundenportal-rel.techem.at';
const RELEASE_INTERNAL = 'portal-int-liferay-rel.dev.traefik.eu.techem.corp';

const LIFERAY_ERROR_REGEX = /Liferay\.\w+ (is not a function|is not a constructor)/;

if (process.env.NODE_ENV === 'production' && window.location.hostname === PRODUCTION_DOMAIN) {
    flare.light('2n1Nxo24wFak3T1fuKwFD4ugjbgmBdrD');
} else if (
    process.env.NODE_ENV === 'production' &&
    (window.location.hostname === RELEASE_DOMAIN || window.location.hostname === RELEASE_INTERNAL)
) {
    flare.light('OaznLY7eAf0evqns2POuQCawfnv2rn5T');
}

flare.beforeEvaluate = error => {
    const hostname = window.location.hostname;
    const stackLines = error.stack?.split('\n') || [];
    for (const line of stackLines) {
        if (
            line.includes(`${hostname}/combo`) ||
            line.includes(`${hostname}/o/frontend-taglib-clay`) ||
            line.includes(`${hostname}/o/frontend-js-web`)
        ) {
            return false;
        }
    }

    if (
        error.message.includes("Cannot read properties of null (reading 'dropContainer')") ||
        LIFERAY_ERROR_REGEX.test(error.message) ||
        error.message.includes('e is null') ||
        error.message.includes('AUI is not defined')
    ) {
        return false;
    }

    return true;
};

flare.beforeSubmit = report => {
    const editedReport = structuredClone(report);

    editedReport.context = {
        ...editedReport.context,
        liferayLocale: window.Liferay?.ThemeDisplay?.getLanguageId() || null,
        organizationId: window.localStorage.getItem('organizationId') || null,
        userId: window.Liferay?.ThemeDisplay?.getUserId() || null,
    };

    return editedReport;
};
